import React from 'react';
import './Partners.css'; // Assuming you're using a separate CSS file for styles
import BOP from './bop-logo.png';
import PalPay from './palpay-logo.png';
import Eureka from './eureka-logo.png';

const Partners = () => {
  const partnersLogos = [BOP, PalPay, Eureka];

  return (
    <div className="partners-container">
      <h2 className="partners-title">شركاؤنا:</h2>
      <div className="partners-logos">
        {partnersLogos.map((logo, index) => (
          <div key={index} className="partner-logo">
            <img src={logo} alt={`Partner ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
