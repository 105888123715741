import React from 'react';
import './Refund.css'; // Reusing the same CSS file for consistency in styling.

const Privacy = () => {
    return (
        <section className="refund-policy">
            <div className="refund-title">
                <h1>سياسة الخصوصيّة</h1>
                <h2>شركة تيلي راد للاستشارات الطبية</h2>
            </div>

            <div className="refund-content">
                <h3>المقدمة</h3>
                <p>
                    تلتزم شركة "تيلي راد للاستشارات الطبية" بحماية خصوصية وسرية المعلومات الشخصية لمستخدمي 
                    خدماتها الطبية عن بُعد. تهدف هذه السياسة إلى توضيح كيفية جمع واستخدام وحماية المعلومات 
                    الشخصية الخاصة بكم.
                </p>

                <h3>المعلومات التي نجمعها</h3>
                <h4>المعلومات التي تشاركها مباشرةً:</h4>
                <ul>
                    <li>الاسم الكامل.</li>
                    <li>معلومات الاتصال.</li>
                    <li>الصور الطبيّة.</li>
                    <li>معلومات الدفع.</li>
                </ul>
                <h4>المعلومات التي يتم جمعها تلقائياً:</h4>
                <ul>
                    <li>عنوان بروتوكول الإنترنت (IP).</li>
                    <li>نوع الجهاز ونظام التشغيل.</li>
                </ul>

                <h3>كيفية استخدام المعلومات</h3>
                <p>
                    نستخدم المعلومات الشخصية للأغراض التالية:
                </p>
                <ul>
                    <li>تقديم الخدمات الطبية عن بُعد.</li>
                    <li>تحسين جودة خدماتنا وتطويرها.</li>
                    <li>الامتثال للمتطلبات القانونية والتنظيمية.</li>
                </ul>

                <h3>مشاركة المعلومات</h3>
                <p>قد نشارك المعلومات الشخصية في الحالات التالية:</p>
                <ul>
                    <li>مع فريقنا الطبي: لنوفّر لك الخدمة والاستشارة الطبية باتقان وأمانة.</li>
                    <li>مع الجهات الحكومية: إذا طُلب منا ذلك بموجب القانون.</li>
                    <li>
                        مع الشركاء والمزودين: الذين يساعدوننا في تشغيل خدماتنا، بشرط التزامهم بحماية خصوصية 
                        المعلومات.
                    </li>
                </ul>

                <h3>حماية المعلومات</h3>
                <p>نتخذ تدابير فنية وإدارية صارمة لحماية معلوماتكم الشخصية، بما في ذلك:</p>
                <ul>
                    <li>التشفير أثناء نقل البيانات.</li>
                    <li>تأمين الخوادم وقواعد البيانات.</li>
                    <li>الوصول المحدود للمعلومات حسب الحاجة.</li>
                </ul>

                <h3>حقوق المستخدمين</h3>
                <p>لديكم الحقوق التالية فيما يتعلق بمعلوماتكم الشخصية:</p>
                <ul>
                    <li>الوصول إلى المعلومات الخاصة بكم.</li>
                    <li>طلب تعديل أو تصحيح البيانات غير الدقيقة.</li>
                    <li>طلب حذف المعلومات (مع مراعاة القيود القانونية).</li>
                    <li>الاعتراض على استخدام بياناتكم لأغراض تسويقية.</li>
                </ul>

                <h3>الاحتفاظ بالمعلومات</h3>
                <p>
                    نحتفظ بالمعلومات الشخصية طالما كانت ضرورية لتقديم الخدمات أو لتحقيق الأغراض المذكورة، إلا 
                    إذا طلب القانون الاحتفاظ بها لفترة أطول.
                </p>

                <h3>ملفات تعريف الارتباط (Cookies)</h3>
                <p>
                    نستخدم ملفات تعريف الارتباط لتحسين تجربة المستخدم. يمكنكم التحكم في إعدادات ملفات تعريف 
                    الارتباط من خلال إعدادات المتصفح الخاص بكم.
                </p>

                <h3>التغييرات في سياسة الخصوصية</h3>
                <p>
                    نحتفظ بالحق في تعديل سياسة الخصوصية هذه من وقت لآخر. سيتم إخطاركم بأي تغييرات هامة عبر 
                    موقعنا.
                </p>

                <h3>الاتصال بنا</h3>
                <p>
                    إذا كانت لديكم أيّة أسئلة أو مخاوف بشأن سياسة الخصوصية، يرجى التواصل معنا عبر:
                </p>
                <ul>
                <li>info@telerad.ps</li>
                <li>59-720-1587 972+</li>
                    <li>العنوان: عمارة السفينة، شارع الإرسال، رام الله، فلسطين</li>
                </ul>

                <p className="footer-note">آخر تحديث: 10/01/2025</p>
            </div>
        </section>
    );
};

export default Privacy;
