import React from 'react';
import './Refund.css'; // You can create a separate CSS file for styling if needed.

const Refund = () => {
    return (
        <section className="refund-policy">
            <div className="refund-title">
                <h1>سياسة الإرجاع</h1>
                <h2>شركة تيلي راد للاستشارات الطبية</h2>
            </div>

            <div className="refund-content">
                <h3>الغرض من السياسة</h3>
                <p>
                    تهدف هذه السياسة إلى ضمان رضا المستفيدين من خلال توفير خيارات واضحة وعادلة لإرجاع أو تبديل 
                    الخدمات المقدمة إذا كانت غير مرضية أو تعرضت لمشكلات تقنية.
                </p>
                <p>
                    تم تصميم هذه السياسة لضمان وضوح العلاقة بين الشركة والمستفيدين وتقديم خدمات ذات جودة عالية مع 
                    مراعاة الشفافية والثقة المتبادلة.
                </p>

                <h3>سياسة الإرجاع</h3>
                <h4>الشروط العامة للإرجاع</h4>
                <p>
                    يحق للمستفيد طلب إرجاع المبلغ المدفوع أو جزء منه في الحالات التالية:
                    <ul>
                        <li>إذا لم تصله الخدمة النهائية بسبب خطأ تقني من الشركة.</li>
                        <li>إذا كانت الخدمة غير مكتملة أو ناقصة، مثل: تقرير غير مكتمل، تقرير غير واضح، تقرير غير مختوم.</li>
                        <li>إذا تم دفع مبلغ زائد عن طريق الخطأ.</li>
                    </ul>
                </p>

                <h4>فترة الإرجاع</h4>
                <p>يمكن للعميل طلب الإرجاع خلال (7) أيام عمل من تاريخ تقديم الخدمة.</p>

                <h4>إجراءات الإرجاع</h4>
                <p>
                    يجب على العميل تقديم طلب مكتوب عبر البريد الإلكتروني أو نموذج التواصل المتاح على المنصة.
                    <ul>
                        <li>يتضمن الطلب: اسم العميل ورقم الهوية أو الحساب.</li>
                        <li>تفاصيل الخدمة ومبررات طلب الإرجاع.</li>
                        <li>إيصال الدفع أو رقم المعاملة.</li>
                    </ul>
                </p>
                <p>
                    يتم معالجة الطلب خلال 5 أيام عمل بعد تقديم جميع المستندات المطلوبة والتأكد من حق المستفيد 
                    بالاسترجاع.
                </p>
                <p>
                    يتم إرجاع المبلغ إلى الحساب البنكي أو البطاقة المستخدمة في الدفع، وبحسب إجراءات البنوك ذات العلاقة.
                </p>

                <h4>الاستثناءات (متى يكون طلبك للإرجاع مرفوضاً من طرف شركة "تيلي راد")</h4>
                <p>
                    لا يمكن إرجاع أو تبديل الخدمات في الحالات التالية:
                    <ul>
                        <li>تم تقديم الخدمة بشكل كامل وصحيح.</li>
                        <li>كان الطلب مبنيًا على تغيير رأي المستفيد دون وجود مشكلة في الخدمة.</li>
                        <li>
                            تأخر المستفيد في إرسال الصور أو المعلومات المطلوبة لإتمام الخدمة عن الوقت المحدد بحسب 
                            هذه السياسات.
                        </li>
                    </ul>
                </p>

                <h4>الالتزامات والضمانات</h4>
                <p>
                    <ul>
                        <li>الشركة ملتزمة بضمان جودة الخدمة وسرعة معالجة الطلبات.</li>
                        <li>الشركة ملتزمة ببنود هذه الوثيقة أمام المستفيدين من خدماتها.</li>
                        <li>
                            المستفيد مسؤول عن تقديم بيانات دقيقة وصحيحة لضمان سرعة استرداد الأموال.
                        </li>
                        <li>
                            المستفيد مسؤول عن تقديم طلب واضح مكتمل العناصر في الوقت المتاح بحسب هذه الوثيقة ومن خلال 
                            الآليات التي تحددها هذه الوثيقة.
                        </li>
                    </ul>
                </p>

                <h4>التواصل والاستفسار</h4>
                <p>
                    للاستفسارات أو تقديم الطلبات، يرجى التواصل عبر:
                    <ul>
                        <li>info@telerad.ps</li>
                        
                        <li>59-720-1587 972+</li>
                    </ul>
                </p>

                <h4>الإقرار بالموافقة</h4>
                <p>
                    قراءتك هذه الوثيقة تعني إقرارك وفهمك لهذه السياسة والشروط والأحكام المذكورة فيها.
                </p>
                <p>
                    تعمل شركة تيلي راد للاستشارات الطبيّة على مراجعة وتطوير هذه السياسة بحسب التغذية الراجعة من 
                    المستفيدين وبما يحقق مبادئ الوضوح والشفافية والعدالة.
                </p>
            </div>
        </section>
    );
};

export default Refund;
